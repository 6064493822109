import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules'

Vue.use(Vuex)

const errorStore = new Vuex.Store({
  state: {
    error: ''
  },
  mutations: {
    addError(state, errorVal) {
      state.error = errorVal;
    }
  }
});

const store = new Vuex.Store({
  state: {
    error: ''
  },
  mutations: {
    addError(state, errorVal) {
      state.error = errorVal;
    }
  },
  modules,
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== 'production',
})

export default store;

export { errorStore }