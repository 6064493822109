import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Vuelidate from 'vuelidate'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueMask from 'v-mask'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueYoutube from 'vue-youtube'

import vco from "v-click-outside"
// import { createStore } from 'vuex';
import router from './router'
import store from '@/state/store'
import i18n from './i18n'

import "@/assets/scss/app.scss";
import "@/assets/scss/icons.scss";

import { initFirebaseBackend } from './helpers/firebase/authUtils';

import { configureFakeBackend } from './helpers/fakebackend/fake-backend';

// import axios from 'axios'
import { authHeader } from './helpers/auth-header'

// import vuetify from './plugins/vuetify'
// Create a new store instance.
// const errorStore = createStore({
//     state () {
//       return {
//         errorList: ''
//       }
//     },
//   })
  
const firebaseConfig = {
    apiKey: process.env.VUE_APP_APIKEY,
    authDomain: process.env.VUE_APP_AUTHDOMAIN,
    databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
    projectId: process.env.VUE_APP_PROJECTId,
    storageBucket: process.env.VUE_APP_STORAGEBUCKET,
    messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
    appId: process.env.VUE_APP_APPId,
    measurementId: process.env.VUE_APP_MEASUREMENTID
};

if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
    initFirebaseBackend(firebaseConfig);
} else {
    configureFakeBackend();
}

Vue.config.productionTip = false
// Vue.use(errorStore)
Vue.use(VueYoutube)
Vue.use(BootstrapVue)
Vue.use(vco)
Vue.use(Vuelidate)
Vue.use(VueSweetalert2)
Vue.use(VueMask)
Vue.use(require('vue-chartist'))
Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE',
        libraries: 'places',
    },
    installComponents: true
})
Vue.component('apexchart', VueApexCharts)

Vue.mixin({
    methods: {
        async retrieveGeneralFromDB() {
            const header = authHeader();
            if (header && header['Authorization']) {
                // const result = await axios.get(`${process.env.VUE_APP_APIURL}/general`, {
                //     headers: header,
                // });

                // if (result && result.data['code'] === 'success') {
                //     const generalData = result.data['data'];
                //     localStorage.setItem('general', JSON.stringify(generalData));
                // }
            }
        },

        getGeneral() {
            return JSON.parse(localStorage.getItem('general'));
        },

        getSelectionItems(records, valueKey, nameKey, nameUpperCase = false) {
            const items = [];
            for (const record of records) {
                const obj = {};
                obj.value = record[valueKey];
                const objLevels = nameKey.split('.');
                let name = record[objLevels[0]];
                for (let i = 1; i < objLevels.length; i++) {
                    name = name[objLevels[i]]; 
                }
                obj.name = nameUpperCase ? name.replace(/_/g, ' ').toUpperCase() : name;
                items.push(obj);
            }
            
            return items;
        },

        getSelectionItemsWithIndex(records) {
            const items = [];
            for (const item of Object.values(records)) {
                const obj = {};
                obj.value = item;
                obj.name = item;
                items.push(obj);
            }
            
            return items;
        },

        getSelectionItemsWithKey(records) {
            const items = [];
            for (const [key, item] of Object.entries(records)) {
                const obj = {};
                obj.value = key;
                obj.name = item;
                items.push(obj);
            }
            
            return items;
        },

        getSelectionItem(items, key) {
            try {
                const item = items.find(obj => obj.value == key);
                if (item) {
                    return item;
                }
            } catch (e) {
                return null;
            }

            return null;
        },

        assignFormData(formData, retrievedData) {
            const data = {};
            for (const key of Object.keys(formData)) {
                if (key in retrievedData) {
                    data[key] = retrievedData[key];
                }
            }

            return data;
        }
    }
});

new Vue({
    router,
    store,
    i18n,
    // vuetify,
    render: h => h(App)
}).$mount('#app')
